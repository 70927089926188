import React from 'react'

const Contact = props => {

  return (
    <div className="cln-home__section cln-contact">
      <h1 className="cln-contact__header">Let's Talk</h1>

      <a  href="mailto:calllistnonstopapp@gmail.com?Subject=Product%20Question"
          className="cln-contact__link">Question about the product</a>
      <a  href="mailto:calllistnonstopapp@gmail.com?Subject=Demo%20Intrest"
          className="cln-contact__link cln-contact__link--purple">Let's see a demo</a>
      <a  href="mailto:calllistnonstopapp@gmail.com?Subject=Misc"
          className="cln-contact__link">Misc.</a>
    </div>
  )
}

export default Contact
