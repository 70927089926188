import Helmet from "react-helmet"
import React from "react"

const Layout = ({ children }) => {

  return (
    <>
      <Helmet>
        <link rel="icon" href="/icons/favicon.ico" />
      </Helmet>
      <div>
        <main>{children}</main>
        <footer className="cln-footer">
          <h2 className="cln-footer__header cln-footer__text">Call List Nonstop</h2>
          <span className="cln-footer__text">© {new Date().getFullYear()}</span>
            <a href="/documents/CLN-TermsofUseandPrivacyPolicy.pdf"
              target="_blank"
              className="cln-footer__text">
            Terms of Use and Privacy Policy
          </a>
        </footer>
      </div>
    </>
  )
}

export default Layout
