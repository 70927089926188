import React from 'react'

const Hero = props => {

  return (
    <div className="cln-home__section cln-hero">
      <h1 className="cln-hero__header">Call List Nonstop</h1>
      <span className="cln-hero__header cln-hero__header--sub">
        Take control of your call list needs with CLN,
        your in-house call center solution.
      </span>
      <img  alt="bottom of div"
            className="cln-hero__bottom"
            src="/images/home-bottom.svg" />
      <img  alt="bottom of div"
            className="cln-hero__right"
            src="/images/home-01.svg" />
      <img  alt="bottom of div"
            className="cln-hero__left"
            src="/images/home-02.svg" />

    </div>
  )
}

export default Hero
