import React from 'react'

const CallToAction = props => {

  return (
    <div className="cln-home__section cln-cta">
      <img  alt="call to action top"
            className="cln-cta__top"
            src="/images/cta-top.svg" />
      <h1 className="cln-cta__header">Signup for Call List Nonstop Now</h1>
      <ul className="cln-cta__ul">
        <li className="cln-cta__li">Raise money for your nonprofit</li>
        <li className="cln-cta__li">Convert more leads for your business</li>
        <li className="cln-cta__li">Reach more voters for your campaign</li>
      </ul>

      <img alt="screen view"
        className="cln-cta__img cln-cta__img--screens"
        src="/images/cta-screens.svg" />

      <a href="https://app.calllistnonstop.com/register" className="cln-cta__signup">Signup Now</a>
      <img  alt="man signing up"
            className="cln-cta__img"
            src="/images/cta-call.svg" />
    </div>
  )
}

export default CallToAction
