import React from 'react';

import Layout from "./components/layout"

import Header from './components/header'
import Hero from './components/hero'
import About from './components/about'
import CallToAction from './components/cta'
import Contact from './components/contact'

import './styles/main.scss';

function App() {
  return (
    <div className="cln-home">
      <Header siteTitle="Call List Nonstop" />
      <Hero />
      <About />
      <CallToAction />
      <Contact />

      <Layout>
      </Layout>
    </div>
  );
}

export default App;
