import React from 'react'

const About = props => {

  return (
    <div className="cln-home__section cln-about">
      <div className="cln-about__block">
        <h2 className="cln-about__block-header">Upload</h2>
        <span className="cln-about__block-text">Call Lists and assign caller accounts to organize your workforce</span>
      </div>
      <div className="cln-about__block cln-about__block--right">
        <img  alt="upload"
              className="cln-about__block-img"
              src="/images/about-upload.svg"/>
      </div>
      <div className="cln-about__block">
        <img  alt="call"
              className="cln-about__block-img"
              src="/images/about-call.svg"/>
      </div>
      <div className="cln-about__block cln-about__block--right">
        <h2 className="cln-about__block-header">Call</h2>
        <span className="cln-about__block-text">from the Call Center portal and knock out lists quicly </span>
      </div>
      <div className="cln-about__block">
        <h2 className="cln-about__block-header">Export</h2>
        <span className="cln-about__block-text">Call Lists with the information gathered</span>
      </div>
      <div className="cln-about__block cln-about__block--right">
        <img  alt="export"
              className="cln-about__block-img"
              src="/images/about-export.svg"/>
      </div>
    </div>
  )
}

export default About
