import React from "react"

const Header = ({ siteTitle }) => (
  <header className="cln-header">
    <div className="cln-header__container">
      <a href="/" className="cln-header__link">
        Home
      </a>
      <a href="https://app.calllistnonstop.com/login" className="cln-header__link cln-header__link--login">
        Login
      </a>
      <a href="https://app.calllistnonstop.com/register" className="cln-header__link cln-header__link--signup">
        Signup
      </a>
    </div>
  </header>
)

export default Header
